<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="作品名称">
        <el-input v-model="form.work_name"></el-input>
      </el-form-item>
      <el-form-item label="作者UCID">
        <el-input v-model="form.author_ucid"></el-input>
      </el-form-item>
      <el-form-item label="作者昵称">
        <el-input v-model="form.author_name"></el-input>
      </el-form-item>
      <el-form-item label="NFTtxid">
        <el-input v-model="form.txid"></el-input>
      </el-form-item>
      <el-form-item label="系列名称">
        <el-input v-model="form.collection_name"></el-input>
      </el-form-item>
      <el-form-item label="合规审核状态">
        <el-select v-model="form.status">
          <el-option label="全部" value=""> </el-option>
          <el-option label="等待审核" value="auditing"> </el-option>
          <el-option label="草稿" value="draft"> </el-option>
          <el-option label="已通过" value="ok"> </el-option>
          <el-option label="已驳回" value="reject"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe row-key="ucid" @sort-change="handleSortChange">
        <el-table-column label="合成品作品名" prop="name"> </el-table-column>
        <el-table-column label="预览图">
          <template slot-scope="scope">
            <img :src="scope.row.album_cover" width="80" />
          </template>
        </el-table-column>
        <el-table-column label="NFTtxid" prop="album_txid"> </el-table-column>
        <el-table-column label="作者信息" prop="author_ucid"
          ><template slot-scope="scope">
            {{ scope.row.author_ucid + `(${scope.row.author_name})` }}
          </template></el-table-column
        >
        <el-table-column label="归属系列" prop="collection_name"></el-table-column>
        <el-table-column label="审核状态" prop="status"></el-table-column>
        <el-table-column label="已合成/可合成数量" prop="nft_count"
          ><template slot-scope="scope">
            {{ `${scope.row.generated_count}/${scope.row.nft_count}` }}
          </template></el-table-column
        >
        <el-table-column
          label="合成开始时间"
          prop="start_at_str"
          sortable="custom"
        ></el-table-column>
        <el-table-column label="合成结束时间" prop="end_at_str"></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === '待审核'"
              size="mini"
              type="primary"
              @click="handleAudit(scope.row)"
            >
              审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog title="合成品审核" :visible.sync="visible" width="30%" center>
        <h4>权益说明</h4>
        <p v-if="!current.rights">暂无</p>
        <p v-for="(right, index) in current.rights" :key="index">{{ right }}</p>
        <h4>权益简介</h4>
        <p v-if="!current.desc">暂无</p>
        <p>{{ current.desc }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleOk">通过审核</el-button>
          <el-button @click="handleCancle">驳回</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getSyntheticList, reviewSynthetics } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  components: {},
  data() {
    return {
      page: 0,
      pageSize: 20,
      total: 0,
      form: {},
      list: [],
      visible: false,
      current: {},
      // 查询参数
      title: "",
      ucid: "",
      mobile: "",
      email: "",
      sortBy: "",
      sortOrder: "",
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    formatMoney: function(value) {
      return value ? `+${value}` : "";
    },
  },
  computed: {
    params() {
      const { work_name, author_ucid, author_name, txid, collection_name, status } = this.form;
      return {
        type: "grocery",
        need_detail: true,
        name: work_name,
        author_ucid: author_ucid,
        author_name: author_name,
        txid: txid,
        collection_name: collection_name,
        status: status,
        page: this.page,
        page_size: this.pageSize,
        sort_by: this.sortBy,
        order_by: this.sortOrder,
      };
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    onQuery() {
      this.page = 0;
      this.fetchList();
    },
    onClear() {
      this.form = {
        work_name: "",
        author_ucid: "",
        author_name: "",
        txid: "",
        collection_name: "",
        status: "",
      };
      this.page = 0;
      this.fetchList();
    },
    async fetchList() {
      try {
        const params = { ...this.params };
        const { data } = await getSyntheticList(params);
        this.list = data.data.batches || [];
        this.total = data.data.total || 0;
      } catch (error) {
        this.list = [];
        this.total = 0;
      }
    },

    handleClickSearch() {
      // this.page = 1;
      // this.pageSize = 20;
      // this.fetchData();
    },

    handleClickReset() {
      this.ucid = "";
      this.mobile = "";
      this.email = "";
      this.list = [];
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSortChange(e) {
      const { prop, order } = e;
      this.sortBy = "start_at";
      this.sortOrder = order === "ascending" ? "asc" : "desc";
      this.fetchList();
    },
    handleAudit(row) {
      this.visible = true;
      this.current = row;
      // this.$router.push("/nft_management/trade?buyer_id=" + row.ucid);
    },
    handleOk() {
      this.reviewSynthetics("ok");
    },
    handleCancle() {
      this.reviewSynthetics("reject");
    },
    async reviewSynthetics(status) {
      let params = {
        id: this.current.id,
        status: status, //ok, reject, auditing
      };
      try {
        const { data } = await reviewSynthetics(params);
        this.$message({
          type: "success",
          message: "编辑成功!",
        });
        this.visible = false;
        this.fetchList();
      } catch (error) {
        this.visible = false;
        console.log(error);
      }
    },
  },
};
</script>
